<template>
  <v-layout row wrap>
    <v-flex xs12 md12>
      <v-card>
        <!-- PESQUISA -->
        <v-toolbar flat class="transparent">
          <v-btn color="green" class="white--text" @click="goCadUsuario()">Novo Usuário</v-btn>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            color="primary"
          ></v-text-field>
        </v-toolbar>

        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          class="elevation-1"
          item-key="id"
          :rows-per-page-items="[5,10,20,50,100]"
          dense
        >
          <template slot="no-data">
            <div class="text-xs-center">Sem registros.</div>
          </template>
          <template v-slot:items="props">
            <tr>
              <td>{{ props.item.nome }}</td>
              <td>{{ props.item.email }}</td>
              <td>{{ props.item.comissao | currency}}</td>
              <td>{{ props.item.ativo ? 'Ativo' : 'Desativado' }}</td>
              <td>{{ props.item.data_cadastro_format }}</td>
              <td class="text-xs-center">
                <v-icon
                  small
                  class="mr-2 td-cursor"
                  slot="activator"
                  @click="editUsuario(props.item)"
                >create</v-icon>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <div class="text-xs-center">Sem resultados para a pesquisa "{{ search }}".</div>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>

const canalModule = () => import('./../../../services/unidade')
const usuarioModule = () => import('./../../../services/usuario')

export default {
  name: 'UsuarioLista',
  data () {
    return {
      search: '',
      headers: [
        { text: 'Nome', align: 'left', value: 'nome' },
        { text: 'E-mail', align: 'left', value: 'email' },
        { text: 'Comissão(%)', align: 'left', value: 'comissao' },
        { text: 'Ativo', align: 'left', value: 'ativo' },
        { text: 'Data de cadastro', align: 'left', value: 'data_cadastro_format' },
        { text: 'Opções', align: 'left', value: '' },
        // { text: 'Usuários', align: 'left', value: '' },
      ],
      items: [],
      canal: {}
    }
  },
  mounted () {
    this.getAll()
    this.getUnidade()
  },

  methods: {
    async getAll () {
      if (this.$route.params.unidade) {
        let usuarioDecode = atob(this.$route.params.unidade)
        let usuarioService = await usuarioModule()
        let resp = await usuarioService.getByUnidade(this.$axios, usuarioDecode)
        if (resp.status === 200 && resp.data) {
          this.items = resp.data
        }
      }
    },
    async getUnidade () {
      let unidadeDecode = atob(this.$route.params.unidade)
      let canalService = await canalModule()
      let resp = await canalService.getByCodigo(this.$axios, unidadeDecode)
      if (resp.status === 200 && resp.data) {
        this.canal = resp.data
      }
    },

    async goCadUsuario () {
      this.$router.push(`/usuario/cad/${btoa(this.canal.codigo)}`)
    },
    async editUsuario (usuario) {
      this.$router.push(`/usuario/cad/${btoa(this.canal.codigo)}/${usuario.codigo}`)
    }
  }
}
</script>